@import url(/react-datepicker.css);
:root {
    --whitebase: #ffffff;

    --black-100: #ebebeb;
    --black-200: #cccccc;
    --black-300: #979797;

    --black-base: #444444;
    --black-500: #333333;

    --primary-500: #1873cc;
    --primary-400-base: #1e90ff;
    --primary-200: #a5d3ff;

    --secondary-700: #330a1f;
    --secondary-600: #66153e;
    --secondary-400-base: #ff349b;
    --secondary-500: #b3246d;
    --secondary-300: #ff71b9;
    --secondary-200: #ffaed7;
    --secondary-100: #ffebf5;

    --tertiary-400-base: #5540fb;
    --tertiary-300: #8879fc;
    --tertiary-200: #bbb3fd;
    --tertiary-100: #ccc6fe;
    --tertiary-500: #3b2db0;
    --tertiary-600: #4d2583;

    --success-100: #d1eedd;
    --success-200: #a3ddbc;
    --success-300: #5dc389;
    --success-400-base: #18a957;
    --success-500: #69923a;
    --success-600: #0a4423;
    --success-700: #052211;

    --warning-100: #fff1d7;
    --warning-200: #ffcb29;
    --warning-300: #ffcf74;
    --warning-400-base: #ff8a38;
    --warning-500: #b38327;
    --warning-600: #664b16;
    --warning-700: #33250b;

    --error-100: #f9d0d9;
    --error-200: #f2a2b3;
    --error-300: #e95c7b;
    --error-400-base: #ff0000;
    --error-500: #9c0f2e;
    --error-600: #59091a;

    --whitebase-10: rgba(255, 255, 255, 0.1);
    --black-40: rgba(0, 0, 0, 0.4);
}

body,
html {
    margin: 0;
    font-family: "Roboto", sans-serif;
    line-height: 19px;
    font-size: 16px;
    color: #444444;
    color: var(--black-base);
    font-weight: normal;
}
* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    outline: none;
}

/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */

    justify-content: center;
    align-items: center;
}

.Header_root__2R_Rn {
    width: 100%;
    background-color: var(--whitebase);
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.Header_sidebar__k-VhH {
    width: 100%;
    background-color: var(--black-100);
}

.Header_buttonWrapper__5wpjb {
    width: 100%;
    max-width: 1377px;
    min-height: 26px;
    padding: 0 20px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Header_wrapper__4Axjt {
    width: 100%;
    max-width: 1377px;
    margin: 0 auto;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
}

.Header_h1__31UXp {
    margin: 0;
    padding: 0;
    display: flex;
}

.Header_logo__3_SJs {
    width: 76px;
    height: 34px;
}

.Header_logoText__121g_ {
    width: 62px;
    height: 33px;
    font-family: "Roboto", sans-serif;
    font-size: 10px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.28px;
    text-align: justify;
    color: #ffffff;
    margin-left: 9px;
}

.Header_signatureIcon__2C-0u {
    margin-left: auto;
    width: 20px;
    height: 19px;
}

.Header_text__G3J9D {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--tertiary-600);
    margin-left: 4px;
}

/* --tablet-widths */
@media (min-width: 660px) {
    .Header_buttonWrapper__5wpjb {
        min-height: 39px;
        max-width: 904px;
    }
    .Header_wrapper__4Axjt {
        height: 118px;
        max-width: 904px;
        padding-left: 24px;
        padding-right: 22px;
    }

    .Header_logo__3_SJs {
        width: 250px;
        height: 61px;
    }

    .Header_logoText__121g_ {
        width: 90px;
        height: 58px;
        font-size: 20px;
        margin-left: 19px;
        letter-spacing: 1.8px;
        line-height: 1;
    }

    .Header_signatureIcon__2C-0u {
        width: 41px;
        height: 36px;
        margin-top: 6px;
    }

    .Header_text__G3J9D {
        font-size: 24px;
        font-weight: bold;
        margin-left: 18px;
        padding-top: 6px;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .Header_buttonWrapper__5wpjb {
        max-width: 1377px;
    }
    .Header_wrapper__4Axjt {
        max-width: 1337px;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
    .Header_logo__3_SJs {
        width: 152px;
        height: 34px;
    }
}

.LogoutButton_root__xxRgj {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: normal;
    color: var(--black-500);
    margin: 0;
    padding: 0 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 4px;
}

.LogoutButton_icon__G7pJ_ {
    margin-left: 7px;
    width: 16px;
    height: 15px;
    object-fit: contain;
    fill: var(--tertiary-600);
}

.LogoutButton_root__xxRgj:active {
    color: var(--black-300);
}

.LogoutButton_root__xxRgj:active .LogoutButton_icon__G7pJ_ {
    fill: var(--tertiary-300);
}

.LogoutButton_root__xxRgj:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

@media (min-width: 660px) {
    .LogoutButton_root__xxRgj {
        font-size: 18px;
        font-weight: bold;
    }

    .LogoutButton_icon__G7pJ_ {
        width: 20px;
        height: 19px;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
}

/* --mobile-widths-only */
@media (max-width: 659px) {
}

.MenuButton_root__3qXOT {
    position: relative;
    width: 18px;
    height: 2px;
    background-color: var(--tertiary-600);
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    border-radius: 4px;
}

.MenuButton_root__3qXOT::before {
    content: "";
    position: absolute;
    top: -4px;
    left: 0;
    width: 18px;
    height: 2px;
    background-color: var(--tertiary-600);
}

.MenuButton_root__3qXOT::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 18px;
    height: 2px;
    background-color: var(--tertiary-600);
}

.MenuButton_root__3qXOT:active {
    background-color: var(--tertiary-300);
}

.MenuButton_root__3qXOT:active::after {
    background-color: var(--tertiary-300);
}

.MenuButton_root__3qXOT:active::before {
    background-color: var(--tertiary-300);
}

.HelpButton_root__3wAN4 {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    color: var(--black-500);
    margin: 0;
    padding: 0 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 4px;
}

.HelpButton_icon__2Voj3 {
    margin-right: 12px;
    width: 20px;
    height: 20px;
    object-fit: contain;
    fill: var(--tertiary-600);
}

.HelpButton_root__3wAN4:active {
    color: var(--black-300);
}

.HelpButton_root__3wAN4:active .HelpButton_icon__2Voj3 {
    fill: var(--tertiary-300);
}

.HelpButton_root__3wAN4:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.Footer_root__VZ9wu {
    width: 100%;
    background-color: var(--tertiary-600);
    min-height: 90px;
}

.Footer_wrapper__1UzXs {
    margin: 0 auto;
    width: 100%;
    max-width: 1377px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 18px 20px;
}

.Footer_text__BdXqz {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: right;
    color: var(--whitebase);
    padding: 0;
    margin: 0;
}

/* --tablet-widths */
@media (min-width: 660px) {
    .Footer_wrapper__1UzXs {
        max-width: 904px;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .Footer_root__VZ9wu {
        height: 118px;
    }

    .Footer_wrapper__1UzXs {
        padding-top: 24px;
        padding-bottom: 22px;
        max-width: 1337px;
    }

    .Footer_text__BdXqz {
        font-size: 14px;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
}

.CareerInformation_root__1uz_e {
    width: 100%;
    max-width: 1377px;
    margin: 0;
    padding: 20px;
    padding-bottom: 10px;
    font-family: "Roboto", sans-serif;
    color: var(--black-base);
}

.CareerInformation_h2__2H9IE {
    padding: 0;
    margin: 0;
    font-family: inherit;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--tertiary-600);
}

.CareerInformation_h3__3MHul {
    padding: 0;
    margin: 0;
    font-family: inherit;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--tertiary-600);
}

.CareerInformation_form__2mPfV {
    margin-top: 22px;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 22px;
    grid-column-gap: 30px;
    align-items: flex-start;
}

.CareerInformation_form2__3_hP0 {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 22px;
    grid-column-gap: 30px;
    align-items: flex-start;
}

.CareerInformation_formSpecializations__2dnLz {
    margin-top: 22px;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 22px;
    grid-column-gap: 30px;
    align-items: flex-start;
}

.CareerInformation_inputText__2ZlNe {
    padding: 0 25px;
    padding-right: 40px;
    margin: 0;
    margin-top: 14px;
    font-family: inherit;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: inherit;
}

.CareerInformation_text__JepGe {
    margin-top: 34px;
    padding: 0;
    font-family: inherit;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: inherit;
    text-align: end;
}

.CareerInformation_text__JepGe span {
    color: var(--secondary-400-base);
}

@media (min-width: 660px) {
    .CareerInformation_root__1uz_e {
        max-width: 904px;
        margin-left: auto;
        margin-right: auto;

        padding: 20px 23px;
    }
    .CareerInformation_form__2mPfV {
        margin-top: 62px;
        grid-row-gap: 31px;
    }

    .CareerInformation_form2__3_hP0 {
        margin-top: 20px;
        grid-row-gap: 31px;
    }

    .CareerInformation_formSpecializations__2dnLz {
        margin-left: 122px;
        /* grid-template-columns: 10px 1fr 10px 1fr; */
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
    }

    .CareerInformation_inputText__2ZlNe {
        padding: 0;
        padding-right: 10px;
    }

    .CareerInformation_h2__2H9IE {
        font-size: 36px;
        line-height: 0.9;
    }

    .CareerInformation_text__JepGe {
        font-size: 14px;
        font-weight: 300;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .CareerInformation_root__1uz_e {
        padding: 0;
        max-width: 858px;
    }
    .CareerInformation_h2__2H9IE {
        font-size: 36px;
        line-height: 0.9;
    }
    .CareerInformation_form__2mPfV {
        margin-top: 81px;
        grid-row-gap: 33px;
    }

    .CareerInformation_form2__3_hP0 {
        margin-top: 20px;
        grid-row-gap: 33px;
    }

    .CareerInformation_inputText__2ZlNe {
        padding: 0;
        padding-right: 10px;
    }

    .CareerInformation_text__JepGe {
        margin-top: 79px;
        margin-bottom: 19px;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
}

.Label_root__1-b53 {
    width: 100%;
    padding: 0;
    display: block;
}

.Label_text__1StrW {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-base);
    margin-bottom: 4px;
    margin-left: 3px;
}

.Label_required__3S5ll {
    color: var(--error-400-base);
    font-weight: 700;
}

.Label_errorWrapper__t2THp {
    width: 100%;
    background-color: var(--error-400-base);
    padding: 6px 14px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 16px 24px 0 rgba(48, 49, 51, 0.1);
}

.Label_errorText__3LnxK {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--black-base);
}

@media (min-width: 660px) {
    .Label_text__1StrW {
        grid-area: text;
        text-align: end;
        align-self: flex-start;
        margin-top: 7px;
    }
    .Label_wrapper__3WEzt {
        grid-area: wrapper;
    }

    .Label_errorWrapper__t2THp {
        grid-area: errorWrapper;
    }

    .Label_root__1-b53 {
        display: grid;
        grid-template-columns: 115px 1fr;
        grid-template-rows: auto;
        grid-column-gap: 9px;
        grid-template-areas:
            "text wrapper"
            ". errorWrapper";
        align-items: center;
        justify-content: end;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
}

/* --mobile-widths-only */
@media (max-width: 659px) {
}

.Select_error__fN2qQ {
    margin-top: 3px;
    color: #df1642;
    margin-bottom: 5px;
}

.Main_iframe__3FItb {
    position: absolute;
    height: calc(100% - 56px);
    width: 100%;
    border: solid 5px var(--black-100);
    border-left-width: 1px;
    border-right-width: 1px;
}
.Main_root__1fzJ5 {
    min-height: 900px;
}

.Main_panel__gXvgn {
    width: 100%;
    height: 20px;
    background-color: #e3e3e3;
}

.Main_h2__1fuju {
    margin: 20px;
    font-family: inherit;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--tertiary-600);
}

@media (min-width: 660px) {
    .Main_h2__1fuju {
        font-size: 36px;
        line-height: 0.67;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .Main_iframe__3FItb {
        height: calc(100% - 110px);
    }

    .Main_h2__1fuju {
        margin-top: 59px;
        margin-bottom: 25px;
        font-size: 36px;
        line-height: 0.67;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
}

.UploadDocs_root__2k6gR {
    width: 100%;
    max-width: 1377px;
    margin: 0;
    padding: 20px;
    font-family: "Roboto", sans-serif;
    color: var(--black-base);
}

.UploadDocs_h2__3soSk {
    padding: 0;
    margin: 0;
    font-family: inherit;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--tertiary-600);
    text-transform: uppercase;
}

.UploadDocs_h3__fLFX1 {
    padding: 0;
    margin: 0;
    margin-top: 20px;
    font-family: inherit;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--tertiary-600);
    text-transform: uppercase;
}

.UploadDocs_form__o6BFd {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 30px;
    align-items: flex-start;
}

.UploadDocs_inputText__35Ned {
    padding: 0 16px;
    margin: 0;
    margin-top: 12px;
    font-family: inherit;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: inherit;
}

.UploadDocs_text__2pdYd {
    margin-top: 34px;
    padding: 0;
    font-family: inherit;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: inherit;
    text-align: end;
}

.UploadDocs_note__2T4uy {
    font-size: 14px;
    font-weight: 500;
}

.UploadDocs_working__Uu0sE {
    padding: 20px 0 0;
    font-size: 16px;
    text-align: justify;
    font-style: italic;
}

.UploadDocs_radioLabel__1BEOO {
    padding: 30px 0 0;
}

.UploadDocs_text__2pdYd span {
    color: var(--secondary-400-base);
}

@media (min-width: 660px) {
    .UploadDocs_root__2k6gR {
        max-width: 904px;
        margin: 0 auto;
    }
    /* .form {
        grid-template-columns: 1fr 1fr;
    } */

    .UploadDocs_form2__2rNyF {
        grid-template-columns: 1fr;
    }

    .UploadDocs_h2__3soSk {
        font-size: 36px;
        line-height: 0.67;
    }

    .UploadDocs_currentEmployer__59QTo {
        grid-area: currentEmployer;
        grid-template-columns: 115px 1fr;
    }
    .UploadDocs_position__1RPZx {
        grid-area: position;
    }

    .UploadDocs_phone__1Z9M- {
        grid-area: phone;
    }

    .UploadDocs_address__1fFby {
        grid-area: address;
    }

    .UploadDocs_form__o6BFd {
        display: grid;
        grid-template-columns: 1fr 450px;
        grid-template-rows: auto;
        grid-column-gap: 9px;
        align-items: center;
        justify-content: end;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .UploadDocs_root__2k6gR {
        padding: 0;
        max-width: 858px;
    }
    .UploadDocs_h2__3soSk {
        font-size: 36px;
        line-height: 0.67;
    }
    .UploadDocs_h3__fLFX1 {
        margin-top: 50px;
        font-size: 18px;
        line-height: 1.33;
    }

    .UploadDocs_form__o6BFd {
        margin-top: 10px;
        grid-row-gap: 16px;
        grid-column-gap: 13px;
    }

    .UploadDocs_inputText__35Ned {
        padding: 0;
        padding-right: 10px;
    }

    .UploadDocs_text__2pdYd {
        margin-top: 79px;
        margin-bottom: 19px;
    }

    .UploadDocs_address__1fFby textarea {
        height: 30px;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
    .UploadDocs_hidden__25WpD {
        display: none;
    }
}

.UploadDocs_labelRadios__3k197 {
    justify-content: left;
}

.UploadInput_form__tnLhF {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 9px;
    grid-template-areas:
        "currentEmployer currentEmployer"
        "position phone"
        "address address";
    align-items: center;
    justify-content: end;
}

.UploadInput_root__2Hm5O {
    display: block;
    float: left;
    border: 1px solid #2f78b9;
    text-align: center;
    margin-bottom: 15px;
    font-weight: bold;
    height: 43px;
}

.UploadInput_root__2Hm5O label:hover {
    background: #ffffff;
    color: #000000;
    cursor: pointer;
}

.UploadInput_root__2Hm5O label {
    color: var(--black-base);
    cursor: pointer;
    margin-top: 1rem;
    width: 100%;
    line-height: 40px;
    margin: 0;
    display: inherit;
    width: 100%;
}

.UploadInput_fileImg__2uM-b {
    margin-bottom: 15px;
}

.UploadInput_fileImg__2uM-b table {
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
}

.UploadInput_fileImg__2uM-b table:hover {
    background-color: #efefef;
}
.UploadInput_fileImg__2uM-b td:nth-child(1) {
    width: 80%;
}

.UploadInput_fileImg__2uM-b td:nth-child(2) {
    width: 10%;
}

.UploadInput_fileImg__2uM-b td:nth-child(3) {
    width: 10%;
    cursor: pointer;
}

.UploadInput_fileImg__2uM-b table {
    border: 1px solid #dee2e6;
}

.UploadInput_fileImg__2uM-b svg {
    width: 50px;
    height: 36px;
    margin-top: 4px;
    color: #3688be;
}

.UploadInput_fileImg__2uM-b table tr td a i {
    color: #3688be;
}

.UploadInput_fileImg__2uM-b table tr td i {
    color: var(--tertiary-700);
}

.SubmissionForm_root__1tU28 {
    width: 100%;
    max-width: 1377px;
    margin: 0;
    padding: 20px;
    font-family: "Roboto", sans-serif;
    color: var(--black-base);
}

.SubmissionForm_h2__awwkE {
    padding: 0;
    margin: 0;
    font-family: inherit;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--tertiary-600);
    text-transform: uppercase;
}

.SubmissionForm_h3__3QXyd {
    padding: 0;
    margin: 0;
    margin-top: 20px;
    font-family: inherit;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--tertiary-600);
    text-transform: uppercase;
}

.SubmissionForm_form__2coly {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 30px;
    align-items: flex-start;
}

.SubmissionForm_inputText__hO_8n {
    padding: 0 16px;
    margin: 0;
    margin-top: 12px;
    font-family: inherit;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: inherit;
}

.SubmissionForm_text__z_MWn {
    margin-top: 34px;
    padding: 0;
    font-family: inherit;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: inherit;
    text-align: end;
}

.SubmissionForm_note__3F7DI {
    font-size: 14px;
    font-weight: 500;
}

.SubmissionForm_text__z_MWn span {
    color: var(--secondary-400-base);
}

@media (min-width: 660px) {
    .SubmissionForm_root__1tU28 {
        max-width: 904px;
        margin: 0 auto;
    }
    .SubmissionForm_form__2coly {
        grid-template-columns: 1fr 1fr;
    }

    .SubmissionForm_h2__awwkE {
        font-size: 36px;
        line-height: 0.67;
    }

    .SubmissionForm_currentEmployer__3NWqG {
        grid-area: currentEmployer;
        grid-template-columns: 115px 1fr;
    }
    .SubmissionForm_position__DMIe9 {
        grid-area: position;
    }

    .SubmissionForm_phone__6lxm9 {
        grid-area: phone;
    }

    .SubmissionForm_address__3Ffn3 {
        grid-area: address;
    }

    .SubmissionForm_form__2coly {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-column-gap: 9px;
        grid-template-areas:
            "currentEmployer currentEmployer"
            "position phone"
            "address address";
        align-items: center;
        justify-content: end;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .SubmissionForm_root__1tU28 {
        padding: 0;
        max-width: 858px;
    }
    .SubmissionForm_h2__awwkE {
        font-size: 36px;
        line-height: 0.67;
    }
    .SubmissionForm_h3__3QXyd {
        margin-top: 50px;
        font-size: 18px;
        line-height: 1.33;
    }

    .SubmissionForm_form__2coly {
        margin-top: 10px;
        grid-row-gap: 16px;
        grid-column-gap: 13px;
    }

    .SubmissionForm_inputText__hO_8n {
        padding: 0;
        padding-right: 10px;
    }

    .SubmissionForm_text__z_MWn {
        margin-top: 79px;
        margin-bottom: 19px;
    }

    .SubmissionForm_address__3Ffn3 textarea {
        height: 30px;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
    .SubmissionForm_hidden__2MImg {
        display: none;
    }
}

.Loader_loadingContainer__2ivQB {
    height: 100%;
    width: 100%;
    background-color: hsla(0, 0%, 100%, 0.999);
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Loader_logo__3ksC0 {
    width: 250px;
    height: 61px;
}

.Loader_visible__1neyA {
    z-index: 9999;
    display: flex;
}

.Loader_invisible__242fr {
    z-index: 0;
    display: none;
}

.Loader_loadingContainer__2ivQB .Loader_LoaderBars__23pdi {
    width: 90px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Loader_loadingContainer__2ivQB .Loader_LoaderBars__23pdi .Loader_Bar__1Lyh6:first-child {
    background-color: #5f3f8f;
    animation: Loader_loadbars__17ACP 0.9s ease-in-out 0s infinite;
    -webkit-animation: Loader_loadbars__17ACP 1s ease-in-out 0s infinite;
}

.Loader_loadingContainer__2ivQB .Loader_LoaderBars__23pdi .Loader_Bar__1Lyh6:nth-child(2) {
    background-color: #9783b8;
    animation: Loader_loadbars__17ACP 0.9s ease-in-out -0.2s infinite;
    -webkit-animation: Loader_loadbars__17ACP 1s ease-in-out -0.2s infinite;
}

.Loader_loadingContainer__2ivQB .Loader_LoaderBars__23pdi .Loader_Bar__1Lyh6:nth-child(3) {
    background-color: #fed438;
    animation: Loader_loadbars__17ACP 0.9s ease-in-out -0.4s infinite;
    -webkit-animation: Loader_loadbars__17ACP 1s ease-in-out -0.4s infinite;
}

.Loader_loadingContainer__2ivQB img {
    margin-bottom: 20px;
    background-color: var(--black-700);
    border-radius: 3px;
}
.Loader_loadingContainer__2ivQB h4 {
    text-align: center;
}

.Loader_loadingContainer__2ivQB .Loader_LoaderBars__23pdi .Loader_Bar__1Lyh6 {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    box-shadow: 0 100px 20px rgba(0, 0, 0, 0.2);
}

@-webkit-keyframes Loader_loadbars__17ACP {
    0% {
        height: 10px;
        margin-top: 25px;
    }
    50% {
        height: 50px;
        margin-top: 0;
    }
    100% {
        height: 10px;
        margin-top: 25px;
    }
}

@keyframes Loader_loadbars__17ACP {
    0% {
        height: 10px;
        margin-top: 25px;
    }
    50% {
        height: 50px;
        margin-top: 0;
    }
    100% {
        height: 10px;
        margin-top: 25px;
    }
}

.App_root__2h8CC {
    position: relative;
}
.App_main__3ZkGI {
    width: 100%;
    min-height: calc(100vh - 268px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    position: relative;
}

.App_root__2h8CC > footer {
    margin-top: auto;
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .App_root__2h8CC > footer {
        grid-area: footer;
    }
    .App_root__2h8CC > header {
        grid-area: header;
    }
    .App_main__3ZkGI {
        grid-area: main;
        margin: 0;
        padding: 0;
        margin-top: 40px;
    }
    .App_navigation__3pqZF {
        grid-area: navigation;
        margin-top: 148px;
    }
    .App_navigationButton__PrbS7 {
        grid-area: navigationButton;
    }

    .App_root__2h8CC {
        display: grid;
        grid-template-columns: 1fr 1040px 272px 1fr;
        grid-template-rows: auto;
        grid-column-gap: 41px;
        grid-template-areas:
            "header header header header"
            ". main navigation ."
            ". navigationButton . ."
            "footer footer footer footer";
    }
}

.NavigationButton_root__1zJkt {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 1377px;
    padding: 0 20px;
    padding-right: 17px;
    margin: 0 auto;
    margin-bottom: 29px;
}

.NavigationButton_button__1HQyZ {
    box-sizing: border-box;
    font-family: inherit;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 12px;
    letter-spacing: normal;
    color: var(--tertiary-600);
    background-color: var(--whitebase);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 27px;
    min-width: 60px;
    padding: 2px 6px;
    border: 2px solid var(--tertiary-600);
    margin: 0;
    cursor: pointer;
    outline: none;
}

.NavigationButton_icon__2FXz0 {
    width: 12px;
    height: 12px;
    margin: 0;
    margin-right: 2px;
    fill: var(--tertiary-600);
}

.NavigationButton_button__1HQyZ:active {
    color: var(--tertiary-300);
    border-color: var(--tertiary-300);
}

.NavigationButton_button__1HQyZ:active .NavigationButton_icon__2FXz0 {
    fill: var(--tertiary-300);
}

.NavigationButton_button__1HQyZ:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.NavigationButton_button__1HQyZ:disabled {
    cursor: auto;
    color: var(--black-300);
    border-color: var(--black-300);
    background-color: var(--whitebase);
}

.NavigationButton_button__1HQyZ:disabled .NavigationButton_icon__2FXz0 {
    fill: var(--black-300);
}

.NavigationButton_buttonNext__1g97c {
    border-color: var(--warning-200);
    background-color: var(--warning-200);
    padding-right: 3px;
    padding-left: 6px;
    letter-spacing: 0.3px;
}

.NavigationButton_buttonNext__1g97c .NavigationButton_icon__2FXz0 {
    transform: rotate(180deg);
    margin: 0;
    margin-left: 4px;
    margin-right: 2px;
}

.NavigationButton_buttonNext__1g97c:active {
    border-color: var(--warning-300);
    background-color: var(--warning-300);
}

.NavigationButton_buttonSave__2QJn2 {
    border-color: var(--tertiary-600);
    background-color: var(--tertiary-600);
    color: var(--whitebase);
}

.NavigationButton_buttonSave__2QJn2:active {
    border-color: var(--tertiary-300);
    background-color: var(--tertiary-300);
    color: var(--whitebase);
}

/* --tablet-widths */
@media (min-width: 660px) {
    .NavigationButton_root__1zJkt {
        justify-content: flex-end;
        max-width: 904px;
        margin: 0 auto;
        margin-bottom: 29px;
        margin-top: 21px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .NavigationButton_button__1HQyZ {
        height: 32px;
        font-size: 14px;
        width: 94px;
    }

    .NavigationButton_icon__2FXz0 {
        width: 14px;
        height: 14px;
        margin-right: 7px;
    }

    .NavigationButton_buttonNext__1g97c {
        margin-left: 22px;
        width: 170px;
    }

    .NavigationButton_buttonNext__1g97c .NavigationButton_icon__2FXz0 {
        margin-left: 6px;
    }

    .NavigationButton_buttonSave__2QJn2 {
        margin-left: 178px;
        width: 90px;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .NavigationButton_root__1zJkt {
        padding: 0;
        max-width: 1337px;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
}

.Navigation_root__5-RED {
    position: absolute;
    z-index: 10;
    top: 26px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 890px;
    margin: 0 auto;
    padding: 13px 0;
    background-color: var(--warning-200);
}

.Navigation_list__2ZMOY {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
}

@media (min-width: 660px) {
    .Navigation_root__5-RED {
        position: relative;
        background-color: transparent;
        padding: 0 10px;
        margin: 0 auto;
        margin-top: 24px;
        margin-bottom: 65px;
    }

    .Navigation_list__2ZMOY {
        min-height: 45px;
        border-radius: 4px;
        border: solid 1px var(--black-300);
        background-color: var(--black-100);
        padding: 0 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .Navigation_root__5-RED {
        width: 272px;
        padding: 0;
        margin: 0;
    }

    .Navigation_list__2ZMOY {
        flex-direction: column;
        background-color: transparent;
        border: none;
        width: 100%;
        padding: 0;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
    /* .root::before {
        content: "";
        position: absolute;
        top: 26px;
        width: 100%;
        height: 100vh;
        display: block;
        z-index: 5;
        background-color: var(--black-40);
    } */
}

.Item_root__2-fiQ {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
}

.Item_button__2N9yH {
    padding: 0 20px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-500);
    border: none;
    outline: none;
    background-color: transparent;
    margin: 0;
    height: 32px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding-right: 20px;
    text-transform: uppercase;
}

.Item_icon__1K8nI {
    width: 14px;
    height: 14px;
    margin-right: 7px;
    fill: var(--success-500);
}

.Item_iconMinus__1BqNI {
    margin-right: 7px;
    fill: var(--black-base);
}

.Item_triangleIcon__YyxF- {
    width: 14px;
    height: 8px;
    transform: rotate(-90deg);
    margin-left: auto;
}

.Item_triangleIconRotate__1iHfJ {
    transform: rotate(0);
}

@media (min-width: 660px) {
    .Item_root__2-fiQ {
        margin-right: 22px;
        position: relative;
    }

    .Item_root__2-fiQ::after {
        position: absolute;
        content: "/";
        right: -11px;
        color: var(--tertiary-600);
        font-size: 14px;
        line-height: 14px;
        top: calc(50% - 7px);
    }

    .Item_root__2-fiQ:last-child::after {
        display: none;
    }

    .Item_icon__1K8nI {
        width: 14px;
        height: 14px;
        border-radius: none;
        fill: var(--success-500);
        background-color: transparent;
        padding: 0;
    }

    .Item_iconMinus__1BqNI {
        fill: var(--tertiary-600);
        margin-right: 5px;
    }

    .Item_button__2N9yH {
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--tertiary-600);
        padding: 0;
        text-transform: capitalize;
    }

    .Item_button__2N9yH span {
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
    }

    .Item_triangleIcon__YyxF- {
        fill: var(--tertiary-600);
        transform: rotate(0);
        width: 9px;
        margin-left: 3px;
    }

    .Item_triangleIconRotate__1iHfJ {
        transform: rotate(0);
    }

    .Item_buttonChecked__2gZi1 span {
        color: var(--success-500);
        font-weight: bold;
    }

    .Item_buttonChecked__2gZi1 .Item_triangleIcon__YyxF- {
        fill: var(--success-500);
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .Item_root__2-fiQ {
        width: 100%;
        margin: 0;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
    }

    .Item_root__2-fiQ::after {
        display: none;
    }

    .Item_iconMinus__1BqNI {
        fill: var(--whitebase);
        margin: 0;
        width: 13px;
        height: 13px;
    }

    .Item_button__2N9yH {
        background-color: var(--tertiary-600);
        width: 100%;
        font-family: inherit;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--whitebase);
        height: 40px;
        padding-left: 7px;
    }

    .Item_button__2N9yH span {
        margin-left: 13px;
        max-width: 205px;
    }

    .Item_triangleIcon__YyxF- {
        order: -1;
        fill: var(--whitebase);
        width: 14px;
        height: 14px;
        transform: rotate(-90deg);
        margin-right: 10px;
    }

    .Item_triangleIconRotate__1iHfJ {
        transform: rotate(0);
    }

    .Item_icon__1K8nI {
        width: 13px;
        height: 13px;
        fill: var(--whitebase);
        margin: 0;
    }

    .Item_buttonChecked__2gZi1 {
        background-color: var(--success-500);
    }

    .Item_buttonChecked__2gZi1 span {
        color: var(--whitebase);
        font-weight: bold;
    }

    .Item_buttonChecked__2gZi1 .Item_triangleIcon__YyxF- {
        fill: var(--whitebase);
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
}

.SubMenu_root__3ci7a {
    width: 100%;
    background-color: var(--whitebase);
    padding: 10px 29px;
    padding-bottom: 14px;
    list-style: none;
    margin: 0;
    border-top: solid 8px var(--warning-200);
    border-bottom: solid 8px var(--warning-200);
}

.SubMenu_item__3tY3W {
    height: 28px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.SubMenu_button__3bTYY {
    font-family: "Roboto", sans-serif;
    height: 19px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    outline: none;
    border: none;
    background-color: transparent;
}

.SubMenu_button__3bTYY:active {
    background-color: var(--black-100);
}

.SubMenu_button__3bTYY span {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-500);
}

.SubMenu_icon__37sIX {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    padding: 3px;
    margin-right: 15px;
    fill: var(--whitebase);
    background-color: var(--success-500);
}

.SubMenu_circleIcon__gPui_ {
    width: 13px;
    height: 13px;
    margin-right: 15px;
    fill: var(--success-500);
}

.SubMenu_iconMinus__1E8-C {
    width: 13px;
    height: 13px;
    margin-right: 15px;
    fill: var(--tertiary-600);
}

.SubMenu_selected__QWMM3 {
    background-color: #e4dfef;
}

@media (min-width: 660px) {
    .SubMenu_root__3ci7a {
        position: absolute;
        top: 27px;
        left: 16px;
        z-index: 10;
        min-width: 130px;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        border: solid 1px var(--black-300);
        background-color: var(--whitebase);
        padding: 0;
        padding-bottom: 2px;
    }

    .SubMenu_item__3tY3W {
        padding: 0;
        border-bottom: solid 0.5px var(--black-100);
        height: 32px;
    }

    .SubMenu_button__3bTYY {
        padding: 0;
        width: auto;
        height: 30px;
        width: 100%;
        align-items: center;
        padding-left: 13px;
        padding-top: 7px;
    }

    .SubMenu_button__3bTYY:active {
        background-color: var(--black-100);
    }

    .SubMenu_button__3bTYY span {
        display: block;
        margin-left: 9px;
        max-width: 95px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .SubMenu_icon__37sIX {
        margin: 0;
        width: 10px;
        height: 10px;
        background-color: transparent;
        fill: var(--success-500);
        padding: 0;
    }

    .SubMenu_iconMinus__1E8-C {
        margin: 0;
        width: 14px;
        height: 14px;
        background-color: transparent;
        fill: var(--tertiary-600);
        padding: 0;
        margin-right: -3px;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .SubMenu_root__3ci7a {
        position: relative;
        top: 0;
        left: 0;
        border-radius: 0;
        box-shadow: none;
        border: none;
        min-width: 272px;
        padding: 0;
        display: flex;
        flex-direction: column;
    }

    .SubMenu_item__3tY3W {
        width: 100%;
        height: auto;
        margin-bottom: -0.5px;
        margin-top: -0.5px;
        border: none;
    }

    .SubMenu_button__3bTYY {
        width: 100%;
        height: 41px;
        border: solid 0.5px var(--black-300);
        display: block;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 8px 35px;
        cursor: pointer;
    }

    .SubMenu_button__3bTYY span {
        font-size: 18px;
        font-weight: 300;
        max-width: 200px;
        margin-left: 14px;
    }

    .SubMenu_icon__37sIX {
        width: 11px;
        height: 11px;
    }

    .SubMenu_iconMinus__1E8-C {
        height: 17px;
        width: 17px;
        margin-right: -6px;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
}

/* popup */

.SignaturePopup_popup__3wSML {
    display: flex;
    align-items: stretch;
    background-color: var(--whitebase);
    padding: 0;
    position: relative;
    top: calc(50% - 240px);
    left: calc(50% - 350px);
    width: 726px;
    min-height: 339px;
}

.SignaturePopup_iconWrapper__2DsOg {
    position: relative;
    width: 64px;
    background-color: var(--primary-400-base);
}

.SignaturePopup_icon__36PtJ {
    position: absolute;
    width: 39px;
    height: 44px;
    top: 54px;
    left: calc(50% - 16.5px);
    fill: var(--whitebase);
}

.SignaturePopup_body__3U5KL {
    padding-top: 23px;
    padding-bottom: 32px;
    padding-right: 64px;
    padding-left: 67px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.SignaturePopup_closeButtonWrapper__32HFE {
    align-self: flex-end;
}

.SignaturePopup_title__2LjkB {
    margin: 0;
    padding: 0;
    font-family: "Raleway", sans-serif;
    font-size: 37px;
    line-height: 52px;
    color: var(--black-base);
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.SignaturePopup_signatureWrapper__2-ggx {
    margin-top: 65px;
    display: flex;
    width: 529px;
    justify-content: space-between;
}

.SignaturePopup_buttonWrapper__1_BWE {
    margin-top: 78px;
    display: flex;
    justify-content: space-between;
}

.SignaturePopup_buttonInner__1_77N {
    width: 202px;
    display: flex;
    justify-content: space-between;
}

.SignaturePopup_signaturePad__FOp7j {
    width: 250px;
    height: 141px;
    border: solid 2px var(--black-100);
}

.SignaturePopup_signatureText__ombYl {
    width: 250px;
    height: 141px;
    border: solid 2px var(--black-100);
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--black-200);
    padding: 8px 16px;
}

.SignaturePopup_currentSignature__3v77Y {
    width: 250px;
    height: 141px;
    border: solid 2px var(--black-100);
}

.PopupButton_button__GY_B0 {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 8px 8px;
    border: 2px solid var(--primary-400-base);
    border-radius: 4px;
    height: 40px;
    min-width: 88px;
    background-color: var(--whitebase);
    color: var(--primary-400-base);
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    white-space: nowrap;
    outline: none;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.PopupButton_button__GY_B0:active {
    background-color: var(--black-100);
    outline: none;
}

.PopupButton_button__GY_B0:disabled {
    background-color: var(--black-100);
    color: var(--primary-200);
    cursor: auto;
    border-color: var(--black-100);
}

.PopupButton_button__GY_B0:focus {
    box-shadow: 0 0 0 2px var(--primary-500);
    outline: none;
}

.PopupButton_primary__2F6oq {
    background-color: var(--primary-400-base);
    color: var(--whitebase);
}

.PopupButton_primary__2F6oq:active {
    background-color: var(--primary-300);
}

.PopupButton_secondary__1deUD {
    background-color: var(--secondary-400-base);
    border-color: var(--secondary-400-base);
    color: var(--whitebase);
}

.PopupButton_secondary__1deUD:disabled {
    background-color: var(--secondary-200);
    border-color: var(--secondary-200);
    color: var(--whitebase);
}

.PopupButton_secondary__1deUD:active {
    background-color: var(--secondary-300);
    border-color: var(--secondary-300);
}

.CloseButton_button__2njrJ {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 4px;
    height: 30px;
    min-width: 30px;
    background-color: transparent;
    cursor: pointer;
    outline: none;
}

.CloseButton_icon__Avww3 {
    fill: var(--black-200);
    height: 30px;
    width: 30px;
}

.CloseButton_button__2njrJ:active .CloseButton_icon__Avww3 {
    fill: var(--black-300);
}

.CloseButton_button__2njrJ:focus {
    outline: none;
}

.CloseButton_button__2njrJ:active {
    outline: none;
}

.CloseButton_button__2njrJ:disabled .CloseButton_icon__Avww3 {
    fill: var(--black-100);
    cursor: auto;
}

.CloseButton_button__2njrJ:focus {
    box-shadow: 0 0 0 2px var(--primary-500);
}

