.loadingContainer {
    height: 100%;
    width: 100%;
    background-color: hsla(0, 0%, 100%, 0.999);
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 250px;
    height: 61px;
}

.visible {
    z-index: 9999;
    display: flex;
}

.invisible {
    z-index: 0;
    display: none;
}

.loadingContainer .LoaderBars {
    width: 90px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.loadingContainer .LoaderBars .Bar:first-child {
    background-color: #5f3f8f;
    animation: loadbars 0.9s ease-in-out 0s infinite;
    -webkit-animation: loadbars 1s ease-in-out 0s infinite;
}

.loadingContainer .LoaderBars .Bar:nth-child(2) {
    background-color: #9783b8;
    animation: loadbars 0.9s ease-in-out -0.2s infinite;
    -webkit-animation: loadbars 1s ease-in-out -0.2s infinite;
}

.loadingContainer .LoaderBars .Bar:nth-child(3) {
    background-color: #fed438;
    animation: loadbars 0.9s ease-in-out -0.4s infinite;
    -webkit-animation: loadbars 1s ease-in-out -0.4s infinite;
}

.loadingContainer img {
    margin-bottom: 20px;
    background-color: var(--black-700);
    border-radius: 3px;
}
.loadingContainer h4 {
    text-align: center;
}

.loadingContainer .LoaderBars .Bar {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    box-shadow: 0 100px 20px rgba(0, 0, 0, 0.2);
}

@keyframes loadbars {
    0% {
        height: 10px;
        margin-top: 25px;
    }
    50% {
        height: 50px;
        margin-top: 0;
    }
    100% {
        height: 10px;
        margin-top: 25px;
    }
}
