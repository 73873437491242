.iframe {
    position: absolute;
    height: calc(100% - 56px);
    width: 100%;
    border: solid 5px var(--black-100);
    border-left-width: 1px;
    border-right-width: 1px;
}
.root {
    min-height: 900px;
}

.panel {
    width: 100%;
    height: 20px;
    background-color: #e3e3e3;
}

.h2 {
    margin: 20px;
    font-family: inherit;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--tertiary-600);
}

@media (min-width: 660px) {
    .h2 {
        font-size: 36px;
        line-height: 0.67;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .iframe {
        height: calc(100% - 110px);
    }

    .h2 {
        margin-top: 59px;
        margin-bottom: 25px;
        font-size: 36px;
        line-height: 0.67;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
}
