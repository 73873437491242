.root {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 1377px;
    padding: 0 20px;
    padding-right: 17px;
    margin: 0 auto;
    margin-bottom: 29px;
}

.button {
    box-sizing: border-box;
    font-family: inherit;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 12px;
    letter-spacing: normal;
    color: var(--tertiary-600);
    background-color: var(--whitebase);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 27px;
    min-width: 60px;
    padding: 2px 6px;
    border: 2px solid var(--tertiary-600);
    margin: 0;
    cursor: pointer;
    outline: none;
}

.icon {
    width: 12px;
    height: 12px;
    margin: 0;
    margin-right: 2px;
    fill: var(--tertiary-600);
}

.button:active {
    color: var(--tertiary-300);
    border-color: var(--tertiary-300);
}

.button:active .icon {
    fill: var(--tertiary-300);
}

.button:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.button:disabled {
    cursor: auto;
    color: var(--black-300);
    border-color: var(--black-300);
    background-color: var(--whitebase);
}

.button:disabled .icon {
    fill: var(--black-300);
}

.buttonNext {
    border-color: var(--warning-200);
    background-color: var(--warning-200);
    padding-right: 3px;
    padding-left: 6px;
    letter-spacing: 0.3px;
}

.buttonNext .icon {
    transform: rotate(180deg);
    margin: 0;
    margin-left: 4px;
    margin-right: 2px;
}

.buttonNext:active {
    border-color: var(--warning-300);
    background-color: var(--warning-300);
}

.buttonSave {
    border-color: var(--tertiary-600);
    background-color: var(--tertiary-600);
    color: var(--whitebase);
}

.buttonSave:active {
    border-color: var(--tertiary-300);
    background-color: var(--tertiary-300);
    color: var(--whitebase);
}

/* --tablet-widths */
@media (min-width: 660px) {
    .root {
        justify-content: flex-end;
        max-width: 904px;
        margin: 0 auto;
        margin-bottom: 29px;
        margin-top: 21px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .button {
        height: 32px;
        font-size: 14px;
        width: 94px;
    }

    .icon {
        width: 14px;
        height: 14px;
        margin-right: 7px;
    }

    .buttonNext {
        margin-left: 22px;
        width: 170px;
    }

    .buttonNext .icon {
        margin-left: 6px;
    }

    .buttonSave {
        margin-left: 178px;
        width: 90px;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .root {
        padding: 0;
        max-width: 1337px;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
}
